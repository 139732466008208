import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import GafisaInvert from "./pages/GafisaInvert";
import GafisaVinci from "./pages/GafisaVinci";
import GafisaGoInnRepublica from "./pages/GafisaGoInnRepublica";
import TecnisaHighlights from "./pages/TecnisaHighlights";
import AstralSaude from "./pages/astral-saude";
import Pinheiros from "./pages/pinheiros";
import MontKlabin from "./pages/montklabin";
import GafisaIbirapueraPark from "./pages/GafisaIbirapueraPark";
import Caparao from "./pages/caparao";
import Aimores from "./pages/aimores";
import SoulSavassi from "./pages/soul-savassi";
import Piemonte from "./pages/piemonte";
import Solenne from "./pages/solenne";
import YouInc from "./pages/youInc";

import Rni from "./pages/rni";

import Locus from "./pages/locus";

import JardimDosManacas from "./pages/jardim-dos-manacas";
import Croma from "./pages/croma";
import Habitat from "./pages/habitat";
import Herc from "./pages/herc";
import SouzaAraujo from "./pages/souza-araujo";
import EkkoGroup from "./pages/ekko-group";
import Bacaba from "./pages/bacaba";
import CarvalhoESantos from "./pages/carvalho-e-santos";
import MMCortes from "./pages/mm-cortes";
import Unna from "./pages/unna";

import CidadeJockey from "./pages/cidade-jockey";

import LaVistaClubBelem from "./pages/la-vista-club-belem";
import Virgilio426 from "./pages/virgilio-426";

// MundoApto
import MundoApto from "./pages/MundoApto";
import Brooklin from "./pages/brooklin";
import SantaCecilia from "./pages/santa-cecilia";
import Inajar from "./pages/inajar";
import Mooca from "./pages/mooca";
import Piqueri from "./pages/piqueri";

// Tecnisa
import BosquePitangueiras from "./pages/bosque-pitangueiras";
import BosqueCerejeiras from "./pages/bosque-cerejeiras";
import ReservaFigueiras from "./pages/reserva-figueiras";
import Belaterra from "./pages/belaterra";

import MappRioBranco from "./pages/mapp-rio-branco";

// JA RUSSI
import Harmony from './pages/harmony'
import SunnyCoast from './pages/sunny-coast'

// ANDRÉ GUIMARÃES
import ArtStudio from './pages/art-studio'
import BrisaMarina from './pages/brisa-marina'

// PRESTES
import ElevaHauer from './pages/eleva-hauer'
import Vision from './pages/vision'

//PRIDE
import Home433 from './pages/home433'
import London from './pages/london'
import Bliss from './pages/bliss'
import Augen from './pages/augen'
import MonteCarlo from './pages/monte-carlo'
import Zait from './pages/zait'
import Sonne from './pages/sonne'
import RecantoProvence from './pages/recanto-provence'
import Sorano from './pages/sorano'

// CAC ENGENHARIA
import NovaVita from './pages/nova-vita'
import Verdi from './pages/verdi'

// RNI
import ReservaIgara from './pages/reserva-igara'
import ReservaClube from './pages/reserva-clube'
import ReservaCachoeirinha from './pages/reserva-cachoeirinha'
import RniBauru from './pages/rni-bauru'
import RniPelotas from './pages/rni-pelotas'

// SCELTA
import Scelta from './pages/scelta'

// CIDADE PEDRA BRANCA
import PatioDasArtes from './pages/patio-das-artes'

// LOTE 5
import Copa from './pages/copa'
import VidaNova from './pages/vida-nova'

// MRV
import Sensia from './pages/sensia'

// A.Carneiro
import Sette from './pages/sette'

// JM
import UniqueResidenceAtibaia from './pages/unique-residence-atibaia'

// Direcional
import VivaVidaSantaCruz from './pages/viva-vida-santa-cruz'
import OlindaEllis from './pages/olinda-ellis'
import VivaVidaRealengo from './pages/viva-vida-realengo'
import ConquistaJardimGuadalupe from './pages/conquista-jardim-guadalupe'
import ConquistaVilaDutra from './pages/conquista-vila-dutra'
import NovaCaxiasFun from './pages/nova-caxias-fun'
import ConquistaParqueIguacu from './pages/conquista-parque-iguacu'
import ConquistaItanhangaGreen from './pages/conquista-itanhanga-green'
import ConquistaFlorianopolis from './pages/conquista-florianopolis'
import ConquistaOceanica from './pages/conquista-oceanica'
import ReservaDoSol from './pages/reserva-do-sol'
import ReservaSaoCristovao from './pages/reserva-sao-cristovao'
import VivaVidaRecantoClube from './pages/viva-vida-recanto-clube'
import ConquistaNorteClube from './pages/conquista-norte-clube'

// Piemonte
import Qoya from "./pages/qoya"
import Lior from "./pages/lior"
import Nizza from "./pages/nizza"
import Poty from "./pages/poty"
import Amiz from "./pages/amiz"

// Bidese
import Zen from "./pages/zen"
import Lemme from "./pages/lemme"

// Tarjab
import Criare from "./pages/criare"
import Viccino from "./pages/viccino"
import Riserva from "./pages/riserva"

// Cima
import Nio from "./pages/nio"
import Aventus from "./pages/aventus"

// Caparaó
import ReservaGreen from "./pages/reserva-green"

// Celi
import Arbo from "./pages/arbo"

// Isotech
import Link from "./pages/link"

// De Amorim
import BosqueVeneza from "./pages/bosque-veneza"

// Grupo Hurbana
import ReservaDoLeste from "./pages/reserva-do-leste"
import PatioJardins from "./pages/patio-jardins"

// Umã
import Borogodo from "./pages/borogodo"

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/gafisa/invert">
          <GafisaInvert />
        </Route>
        <Route path="/gafisa/vinci">
          <GafisaVinci />
        </Route>
        <Route path="/gafisa/goinnrepublica">
          <GafisaGoInnRepublica />
        </Route>
        <Route path="/tecnisa/highlights">
          <TecnisaHighlights />
        </Route>

        {/* <Route path="/rni">
          <Rni />
        </Route> */}
        
        <Route path="/tecnisa/astral-saude">
          <AstralSaude />
        </Route>
        <Route path="/pinheiros">
          <Pinheiros />
        </Route>
        <Route path="/montklabin">
          <MontKlabin />
        </Route>
        <Route path="/gafisa/ibirapuera">
          <GafisaIbirapueraPark />
        </Route>

        {/* MundoApto */}
        <Route path="/mundoapto/mooca">
          <Mooca />
        </Route>
        <Route path="/mundoapto/piqueri">
          <Piqueri />
        </Route>
        <Route path="/mundoapto/inajar">
          <Inajar />
        </Route>
        <Route path="/mundoapto/brooklin">
          <Brooklin />
        </Route>
        <Route path="/mundoapto/santa-cecilia">
          <SantaCecilia />
        </Route>
        <Route path="/mundoapto">
          <MundoApto />
        </Route>


        <Route path="/locus">
          <Locus />
        </Route>

        {/* Caparao */}
        <Route path="/caparao/jardins">
          <Caparao />
        </Route>
        <Route path="/aimores">
          <Aimores />
        </Route>
        <Route path="/caparao/soul-savassi">
          <SoulSavassi />
        </Route>
        <Route path="/caparao/reserva-green">
          <ReservaGreen />
        </Route>

        {/* Piemonte */}
        <Route path="/piemonte/vizione">
          <Piemonte />
        </Route>
        <Route path="/piemonte/solenne">
          <Solenne />
        </Route>
        <Route path="/youinc">
          <YouInc />
        </Route>
        <Route path="/piemonte/jardim-dos-manacas">
          <JardimDosManacas />
        </Route>
        <Route path="/piemonte/croma">
          <Croma />
        </Route>
        <Route path="/piemonte/unna">
          <Unna />
        </Route>
        <Route path="/piemonte/qoya">
          <Qoya />
        </Route>
        <Route path="/piemonte/lior">
          <Lior />
        </Route>
        <Route path="/piemonte/nizza">
          <Nizza />
        </Route>
        <Route path="/piemonte/poty">
          <Poty />
        </Route>
        <Route path="/piemonte/amiz">
          <Amiz />
        </Route>

        <Route path="/herc">
          <Herc />
        </Route>

        <Route path="/souza-araujo">
          <SouzaAraujo />
        </Route>

        <Route path="/ekko-group">
          <EkkoGroup />
        </Route>

        <Route path="/bacaba">
          <Bacaba />
        </Route>

        <Route path="/carvalho-e-santos">
          <CarvalhoESantos />
        </Route>

        <Route path="/mm-cortes">
          <MMCortes />
        </Route>

        {/* Gafisa */}
        <Route path="/gafisa/cidade-jockey">
          <CidadeJockey />
        </Route>

        {/* Tecnisa */}
        <Route path="/tecnisa/bosque-pitangueiras">
          <BosquePitangueiras />
        </Route>
        <Route path="/tecnisa/bosque-cerejeiras">
          <BosqueCerejeiras />
        </Route>
        <Route path="/tecnisa/reserva-figueiras">
          <ReservaFigueiras />
        </Route>
        <Route path="/tecnisa/belaterra">
          <Belaterra />
        </Route>

        {/* REV3 */}
        <Route path="/rev3/la-vista-club-belem">
          <LaVistaClubBelem />
        </Route>
        <Route path="/rev3/virgilio-426">
          <Virgilio426 />
        </Route>

        {/* JA Russi */}
        <Route path="/ja-russi/harmony">
          <Harmony />
        </Route>
        <Route path="/ja-russi/sunny-coast">
          <SunnyCoast />
        </Route>

        {/* Plano & Plano */}
        <Route path="/plano-e-plano/mapp-rio-branco">
          <MappRioBranco />
        </Route>

        {/* CAC Engenharia */}
        <Route path="/cac/nova-vita">
          <NovaVita />
        </Route>
        <Route path="/cac/verdi">
          <Verdi />
        </Route>

        {/* RNI */}
        <Route path="/rni/reserva-igara">
          <ReservaIgara />
        </Route>
        <Route path="/rni/reserva-clube">
          <ReservaClube />
        </Route>
        <Route path="/rni/reserva-cachoeirinha">
          <ReservaCachoeirinha />
        </Route>
        <Route path="/rni/rni-bauru">
          <RniBauru />
        </Route>
        <Route path="/rni/rni-pelotas">
          <RniPelotas />
        </Route>

        {/* André Guimarães */}
        <Route path="/andre-guimaraes/art-studio">
          <ArtStudio />
        </Route>
        <Route path="/andre-guimaraes/brisa-marina">
          <BrisaMarina />
        </Route>

        {/* Prestes */}
        <Route path="/prestes/eleva-hauer">
          <ElevaHauer />
        </Route>
        <Route path="/prestes/vision">
          <Vision />
        </Route>

        {/* Pride */}
        <Route path="/prestes/home433">
          <Home433 />
        </Route>
        <Route path="/pride/london">
          <London />
        </Route>
        <Route path="/pride/bliss">
          <Bliss />
        </Route>
        <Route path="/pride/augen">
          <Augen />
        </Route>
        <Route path="/pride/monte-carlo">
          <MonteCarlo />
        </Route>
        <Route path="/pride/zait">
          <Zait />
        </Route>
        <Route path="/pride/sonne">
          <Sonne />
        </Route>
        <Route path="/pride/recanto-provence">
          <RecantoProvence />
        </Route>
        <Route path="/pride/sorano">
          <Sorano />
        </Route>

        {/* Scelta */}
        <Route path="/scelta">
          <Scelta />
        </Route>

        {/* Cidade Pedra Branca */}
        <Route path="/cidade-pedra-branca/patio-das-artes">
          <PatioDasArtes />
        </Route>

        {/* Lote 5 */}
        <Route path="/lote-5/copa">
          <Copa />
        </Route>
        <Route path="/lote-5/vida-nova">
          <VidaNova />
        </Route>

        {/* MRV */}
        <Route path="/mrv/sensia">
          <Sensia />
        </Route>

        {/* A.Carneiro */}
        <Route path="/a-carneiro/sette">
          <Sette />
        </Route>

        {/* JM */}
        <Route path="/jm/unique-residence-atibaia">
          <UniqueResidenceAtibaia />
        </Route>

        {/* Direcional */}
        <Route path="/direcional/viva-vida-santa-cruz">
          <VivaVidaSantaCruz />
        </Route>
        <Route path="/direcional/olinda-ellis">
          <OlindaEllis />
        </Route>
        <Route path="/direcional/viva-vida-realengo">
          <VivaVidaRealengo />
        </Route>
        <Route path="/direcional/conquista-jardim-guadalupe">
          <ConquistaJardimGuadalupe />
        </Route>
        <Route path="/direcional/conquista-vila-dutra">
          <ConquistaVilaDutra />
        </Route>
        <Route path="/direcional/nova-caxias-fun">
          <NovaCaxiasFun />
        </Route>
        <Route path="/direcional/conquista-parque-iguacu">
          <ConquistaParqueIguacu />
        </Route>
        <Route path="/direcional/conquista-itanhanga-green">
          <ConquistaItanhangaGreen />
        </Route>
        <Route path="/direcional/conquista-florianopolis">
          <ConquistaFlorianopolis />
        </Route>
        <Route path="/direcional/conquista-oceanica">
          <ConquistaOceanica />
        </Route>
        <Route path="/direcional/reserva-do-sol">
          <ReservaDoSol />
        </Route>
        <Route path="/direcional/reserva-sao-cristovao">
          <ReservaSaoCristovao />
        </Route>
        <Route path="/direcional/viva-vida-recanto-clube">
          <VivaVidaRecantoClube />
        </Route>
        <Route path="/direcional/conquista-norte-clube">
          <ConquistaNorteClube />
        </Route>

        {/* Bidese */}
        <Route path="/bidese/zen">
          <Zen />
        </Route>
        <Route path="/bidese/lemme">
          <Lemme />
        </Route>

        {/* Tarjab */}
        <Route path="/tarjab/criare">
          <Criare />
        </Route>
        <Route path="/tarjab/viccino">
          <Viccino />
        </Route>
        <Route path="/tarjab/riserva">
          <Riserva />
        </Route>

        {/* Cima */}
        <Route path="/cima/nio">
          <Nio />
        </Route>
        <Route path="/cima/aventus">
          <Aventus />
        </Route>

        {/* Celi */}
        <Route path="/celi/arbo">
          <Arbo />
        </Route>

        {/* Isotech */}
        <Route path="/isotech/link">
          <Link />
        </Route>

        {/* De Amorim */}
        <Route path="/de-amorin/bosque-veneza">
          <BosqueVeneza />
        </Route>

        {/* Grupo Hurbana */}
        <Route path="/grupo-hurbana/reserva-do-leste">
          <ReservaDoLeste />
        </Route>
        <Route path="/grupo-hurbana/patio-jardins">
          <PatioJardins />
        </Route>

        {/* Umã */}
        <Route path="/uma/borogodo">
          <Borogodo />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
